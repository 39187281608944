export const CONSOLE_LOG = true;
export const MEETING_LINK = "https://meetings.hubspot.com/technoava";
export const PHP_SERVER_URL = "https://insightstap.com/server/php";

export const BROCHURE_LINK = {
    analytics_studio: "https://insightstap.com/resources/brochure/InsightsTap%20-%20web-&-product-analytics-studio-brochure-&-pricing-v1.pdf",
    cold_outreach_va: "https://insightstap.com/resources/brochure/InsightsTap%20-%20cold-outreach-brochure-&-pricing-v1.pdf",
    hubspot_va: "https://insightstap.com/resources/brochure/InsightsTap%20-%20hubspot-va-brochure-&-pricing.pdf",
    ppc_va: "https://insightstap.com/resources/brochure/InsightsTap%20-%20ppc-brochure-&-pricing-v1.pdf",
    hubspot_development: "https://insightstap.com/resources/brochure/InsightsTap%20-%20hubspot-design-and-development-brochure-&-pricing-v1.pdf"
}

export const menuData = [
    {
        label: "Home",
        link: "../",
    },
    {
        label: "Our Services",
        link: "../services",
    },
    {
        label: "About us",
        link: "../about.html",
    },
];

// {
//     label: "Services",
//     link: "./services/",
//     children: [
//         { label: "Web Development", link: "/services/web-development/" },
//         { label: "Mobile App Development", link: "/services/mobile-app-development/" },
//         { label: "API Development & Integration", link: "/services/api-development/" },
//         { label: "Custom AI Chatbot Development", link: "/services/ai-chatbot-development/" },
//         { label: "Managed Platforms Development", link: "/services/managed-platforms-development/" },
//     ],
// },

export const footerData = {
    description: "We're a strategic digital agency focused on user experience, mobile, social, and data, with 4 years of expertise.",
    email: "connect@technoava.site",
    socialMedia: [
        { href: "#", iconClass: "fa-brands fa-x-twitter" },
        { href: "#", iconClass: "fa-brands fa-facebook-f" },
        { href: "#", iconClass: "fa-brands fa-instagram" },
        { href: "#", iconClass: "fa-brands fa-github" },
        { href: "#", iconClass: "fa-brands fa-linkedin" }
    ],
    subMenu: [
        {
            title: "Navigation",
            links: [
                { text: "Home", url: "../" },
                { text: "Services", url: "./services/" },
                { text: "Pricing", url: "../pricing.html" },
                { text: "About Us", url: "../about.html" },
                { text: "Contact", url: "./contact-us/" },
            ]
        },
        {
            title: "Quick Links",
            links: [
                { text: "Portfolio", url: "./portfolio.html" },
                { text: "Privacy policy", url: "./legal/privacy-policy/" },
                { text: "Terms & Conditions", url: "./legal/terms-and-conditions/" },
                { text: "Shipping Policy", url: "./legal/shipping-policy/" },
                { text: "Cancellation/Refund Policy", url: "./legal/cancellation-and-refund-policy/" },
            ]
        }
    ]
};

// {
//     title: "Services",
//     links: [
//         { text: "Web", url: "./services/web-development/" },
//         { text: "Mobile App", url: "./services/mobile-app-development/" },
//         { text: "API", url: "./services/api-development/" },
//         { text: "Custom AI Chatbot", url: "./services/ai-chatbot-development/" },
//         { text: "Managed Platforms", url: "./services/managed-platforms-development/" },
//     ]
// },

export const testimonials = [
    {
        id: 1,
        name: "Shyam H",
        location: "India",
        title: "HubSpot Optimization Excellence",
        feedback: "Thank you for helping us optimize various hubs on the enterprise and professional version of Hubspot. Highly commendable.",
        picture: "./1.webp",
        rating: 5,
    },
    {
        id: 2,
        name: "Maik J",
        location: "Germany",
        title: "Transformational Tech Marketing",
        feedback: "Ritesh's expertise in tech marketing and automation transformed our startup. His innovative strategies and clear communication drove remarkable brand growth and efficiency. Highly recommended!",
        picture: "./2.webp",
        rating: 4.8,
    },
    {
        id: 3,
        name: "Colin Bland",
        location: "Australia",
        title: "SaaS Product Mentorship",
        feedback: "Ritesh's deep knowledge and mentoring approach greatly enhanced my understanding of our complex SaaS product. He guided me through the funnel-building process, leaving me with valuable skills and a solid strategy.",
        picture: "./3.webp",
        rating: 5,
    },
    {
        id: 4,
        name: "Yew Friend",
        location: "Canada",
        title: "Startup Clarity and Strategy",
        feedback: "Thanks for the fantastic work, which cleared the mind blocks and confusion most startups face at the launch stage. I am excited to implement your designed SaaS Marketing plan.",
        picture: "./4.webp",
        rating: 4.6,
    },
    {
        id: 5,
        name: "Vash Naidoo288",
        location: "United Kingdom",
        title: "Seamless Marketing and Branding",
        feedback: "Ritesh's expertise and approachability made defining my marketing, brand, and funnel plan seamless. He ensures every detail is covered. Highly recommended!",
        picture: "./5.webp",
        rating: 4.9,
    },
    {
        id: 6,
        name: "James Slater",
        location: "United Kingdom",
        title: "Outstanding Sales Funnel Expertise",
        feedback: "Wonderful experience. Value received FAR exceeded the amount I paid. Would highly recommend to anyone look for an expert at building out sales funnels!",
        picture: "./6.webp",
        rating: 5.0,
    },
    {
        id: 7,
        name: "Arymal J",
        location: "Canada",
        title: "Enlightening Discovery Process",
        feedback: "Working with Ritesh was enlightening. His unmatched detail and methodology made the discovery process enjoyable and critical to our success. I’m eager to collaborate again.",
        picture: "./7.webp",
        rating: 4.3,
    },
    {
        id: 8,
        name: "Colleen Wilson",
        location: "United States",
        title: "Amazing Collaboration",
        feedback: "Ritesh was amazing to work with, I would hire him again to help and advise. Great guy!",
        picture: "./8.webp",
        rating: 4.5,
    },
    {
        id: 9,
        name: "David G",
        location: "Australia",
        title: "Clarity in Marketing Direction",
        feedback: "Ritesh brought clarity to our marketing direction, introducing effective strategies and resources. His friendly, communicative approach made him an invaluable team member. We’ll continue working with him.",
        picture: "./9.webp",
        rating: 5.0,
    },
    {
        id: 10,
        name: "Renee Kraft",
        location: "United States",
        title: "Email System and Campaign Strategies",
        feedback: "Took a great deal of time helping figure out the email system to use and creating campaign strategies for online marketing.",
        picture: "./10.webp",
        rating: 4.2,
    }
];
  
  