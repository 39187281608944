import { CONSOLE_LOG, footerData, menuData } from "./constants";
import { getCurrentPageIndex, modifyPageURL, HAS_EXTERNAL_PATH } from "./module";
import logo from "../img/logo/logo_landscape_white.png";
import favicon from "../img/logo/favicon.png";
if (CONSOLE_LOG) console.log("LOGO:", logo);
if (CONSOLE_LOG) console.log("favicon:", favicon);
document.addEventListener("DOMContentLoaded", function () {
    const menu = createMenu(menuData);
    // Create a link element
    const link = document.createElement('link');
    link.rel = 'icon'; // Set the rel attribute
    link.href = favicon; // Set the href attribute to the path of your favicon
    link.type = 'image/png'; // Optional: Set the type of the image

    // Append the link element to the head of the document
    document.head.appendChild(link);
});

function createMenu(menuData) {
    const ul = document.querySelector(".site-menu-main");
  
    menuData.forEach(item => {
        const li = document.createElement('li');
        li.className = 'nav-item';
    
        const a = document.createElement('a');

        const itemURL = modifyPageURL(item.link);
        a.href = itemURL;

        a.className = 'nav-link-item';
        a.textContent = item.label;
  
        li.appendChild(a);
  
      if (item.children) {
        li.classList.add('nav-item-has-children');
        a.innerHTML += `<i class="fas fa-angle-down"></i>`;
        const subUl = document.createElement('ul');
        subUl.className = 'sub-menu';
  
        item.children.forEach(subItem => {
          const subLi = document.createElement('li');
          subLi.className = 'sub-menu--item';
  
          const subA = document.createElement('a');
          subA.href = subItem.link;
          subA.innerHTML = `<span class="menu-item-text">${subItem.label}</span>`;
  
          subLi.appendChild(subA);
          subUl.appendChild(subLi);
        });
  
        li.appendChild(subUl);
      }
  
      ul.appendChild(li);
    });
  
    return true;
}

const footerContainer = document.querySelector(".footer-content");

function createFooterWidget(data) {
    // Create the footer widget div
    const footerWidget = document.createElement('div');
    footerWidget.classList.add('flex', 'flex-col', 'gap-y-7', 'md:col-span-3', 'lg:col-span-1');

    // Create the logo link
    const logoLink = document.createElement('a');
    logoLink.href = "index.html";
    const logoImg = document.createElement('img');
    logoImg.src = logo;
    logoImg.alt = "Masco";
    logoImg.width = 230;
    logoImg.height = 50;
    logoLink.appendChild(logoImg);
    
    // Create the footer content div
    const contentDiv = document.createElement('div');

    // Add footer about text
    const aboutText = document.createElement('div');
    aboutText.classList.add('lg:max-w-[416px]');
    aboutText.textContent = data.description; // Use the description from footerData
    contentDiv.appendChild(aboutText);

    // Create footer email link
    const emailLink = document.createElement('a');
    emailLink.href = `mailto:${data.email}`;
    emailLink.classList.add('my-6', 'block', 'underline-offset-4', 'transition-all', 'duration-300', 'hover:underline');
    emailLink.textContent = data.email; // Use the email from footerData
    contentDiv.appendChild(emailLink);

    // Create social media links container
    const socialDiv = document.createElement('div');
    socialDiv.classList.add('flex', 'flex-wrap', 'gap-5');

    // Loop through social media links
    data.socialMedia.forEach(social => {
        const socialLink = document.createElement('a');
        socialLink.href = modifyPageURL(social.href);
        socialLink.target = "_blank";
        socialLink.rel = "noopener noreferrer";
        socialLink.classList.add('flex', 'h-[30px]', 'w-[30px]', 'items-center', 'justify-center', 'rounded-[50%]', 'bg-white', 'bg-opacity-5', 'text-sm', 'text-white', 'transition-all', 'duration-300', 'hover:bg-ColorBlue');
        socialLink.setAttribute('aria-label', social.iconClass.split(' ')[2]); // Use the icon class to set aria-label

        const icon = document.createElement('i');
        icon.className = social.iconClass; // Set the icon class
        socialLink.appendChild(icon);
        
        socialDiv.appendChild(socialLink); // Append social link to the social div
    });

    contentDiv.appendChild(socialDiv); // Append social div to the content div
    footerWidget.appendChild(logoLink); // Append logo link to the footer widget
    footerWidget.appendChild(contentDiv); // Append content div to the footer widget
    return footerWidget; // Return the constructed footer widget
}

// Append the created footer widget to the footer container
footerContainer.appendChild(createFooterWidget(footerData));


// Function to create the HTML for each footer widget
function createFooterSubWidget(title, links) {
    const widgetDiv = document.createElement('div');
    widgetDiv.classList.add('flex', 'flex-col', 'gap-y-7', 'md:col-span-1', 'lg:col-span-1');

    // Footer Widget Title
    const titleDiv = document.createElement('div');
    titleDiv.classList.add('text-xl', 'font-semibold', 'capitalize');
    titleDiv.textContent = title;
    widgetDiv.appendChild(titleDiv);

    // Footer Navbar
    const ul = document.createElement('ul');
    ul.classList.add('flex', 'flex-col', 'gap-y-[10px]', 'capitalize');
    
    // Creating each link element
    links.forEach(link => {
        const li = document.createElement('li');
        const a = document.createElement('a');
        a.href = modifyPageURL(link.url);
        a.classList.add('hover:opcity-100', 'underline-offset-4', 'opacity-80', 'transition-all', 'duration-300', 'ease-linear', 'hover:underline');
        a.textContent = link.text;
        li.appendChild(a);
        ul.appendChild(li);
    });

    widgetDiv.appendChild(ul);
    return widgetDiv;
}

// Insert footer widgets from subMenu
footerData.subMenu.forEach(menu => {
    const footerWidget = createFooterSubWidget(menu.title, menu.links);
    footerContainer.appendChild(footerWidget);
});

