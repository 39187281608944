import { BROCHURE_LINK, CONSOLE_LOG } from "./constants";
import { fetchDataFromPHP } from "./module";


function handleSubmit(buttonId, formId) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.addEventListener('click', async () => {
            // Create loading dots HTML
            const loadingDots = document.createElement('div');
            loadingDots.classList.add('loading-dots');
            loadingDots.style.display = 'flex'; // Set the initial display to flex
            loadingDots.innerHTML = `
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            `;

            // Append loading dots to the button
            button.appendChild(loadingDots);
            button.querySelector('span').textContent = 'Submitting';
            const originalButtonText = button.querySelector('span').textContent;  // Save original text
   

            if (buttonId === 'brochure-submit-btn') {
                const key = button.getAttribute('data-br-id');
                let currentBrLink = BROCHURE_LINK.ppc_va;
                if (BROCHURE_LINK[key]) {
                    currentBrLink = BROCHURE_LINK[key];
                }
                if (CONSOLE_LOG) console.log("currentBrLink:", currentBrLink);

                const emailObject = {
                    subject: "Your Resource is Ready to Download from InsightsTap",
                    template: "download_brochure.xhtml",
                    calendar_link: "https://meetings.hubspot.com/spandav",
                    brochure_link: currentBrLink
                };
                const formState = await processFormInfo(formId, emailObject);
                
                // Remove loading dots after form submission
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = 'Download Pricing';  // Restore button text

                if (formState) {
                    setTimeout(() => {
                        window.location.href = "./download-brochure";
                    }, 0);
                }
            } else if (buttonId === 'contact-submit-btn') {
                const emailObject = {
                    subject: "Thank You for Reaching Out to Insightstap!",
                    template: "contact_us.xhtml",
                    calendar_link: "https://meetings.hubspot.com/spandav",
                };
                const formState = await processFormInfo(formId, emailObject);
                
                // Remove loading dots after form submission
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = 'Submit';  // Restore button text

                if (formState) {
                    setTimeout(() => {
                        window.location.href = "./thank-you";
                    }, 0);
                }
            } else {
                // Restore the original button text
                button.removeChild(loadingDots);
                button.querySelector('span').textContent = originalButtonText;
            }
        });
    }
}



async function processFormInfo(formId, emailObject) {
    const form = document.getElementById(formId);
    if (!form) {
        console.error(`Form with ID ${formId} not found.`);
        return false;
    }

    const inputs = form.querySelectorAll('input, select, textarea');

    let isValid = true; // Assuming this variable is declared outside the loop
    const formData = {};

    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const isRequired = input.getAttribute('data-required') === '1';

        if (isRequired && !input.value) {
            alert(`Please fill out the required field: ${input.placeholder || input.name}`);
            isValid = false;
            break; // Exit the loop immediately
        }

        if (input.value) {
            formData[input.name] = input.value;
        }
    }


    if (!isValid) return false;

    if(formData.email_id !== ""){
        const validEmail = await validateEmailId(formData.email_id);
        if(!validEmail){
            return false;
        }
    }
   
    const firstName = formData.name.split(" ")[0];
    if (CONSOLE_LOG) console.log("firstName:", firstName);  // Output: "Sam"
    emailObject.first_name = firstName;

    const formObject = {
        tableName: form.getAttribute('data-table-id'),
        form: formData,
        mail: emailObject
    };

    if (CONSOLE_LOG) console.log(formObject);
    return await submitFormData(form, formObject);
}

async function validateEmailId(emailId){
    try {
        if (CONSOLE_LOG) console.log("Email:", emailId);
        const result = await fetchDataFromPHP('/mail_vaildator.php', { email: emailId });
        if (result) {
            if (CONSOLE_LOG) console.log('Email validated.');
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}


async function submitFormData(form, formObject) {
    try {
        const result = await fetchDataFromPHP('/form_submission_v4.php', formObject);
        if (result) {
            //alert(SUCCESS_MESSAGE);
            form.reset();
            return true;
        } else {
            if (CONSOLE_LOG) console.log('Form submission failed.');
            return false;
        }
    } catch (err) {
        console.error('There was an error:', err);
        return false;
    }
}



// Initialize event listeners for buttons
handleSubmit('contact-submit-btn', 'contact-us-form');
handleSubmit('brochure-submit-btn', 'callback-from');